export default {
  index:{
    'status': 'success',
    'data': {
      'data': [
        {
          'id': 1,
          'school_id': 1,
          'name':' allocation name ',
          'grading_type': [],
          'user_id': 9,
          'user': 'SND',
          'Academic_id': null,
          'Academic_name': '2021-2021',
          'start_date': '2021-12-10',
          'end_date': '2021-12-30',
          'apply_credit_hours': 0,
          'subjects': [
            {
              'id': 1,
              'subject_id': 1,
              'add_to_grading_allocation': 1,
              'display_to_report_cared': 1,
              'weight': null,
              'grading_allocation_id': 1
            },
            {
              'id': 2,
              'subject_id': 4,
              'add_to_grading_allocation': 1,
              'display_to_report_cared': 1,
              'weight': null,
              'grading_allocation_id': 1
            },
            {
              'id': 3,
              'subject_id': 5,
              'add_to_grading_allocation': 1,
              'display_to_report_cared': 1,
              'weight': null,
              'grading_allocation_id': 1
            },
            {
              'id': 4,
              'subject_id': 6,
              'add_to_grading_allocation': 1,
              'display_to_report_cared': 1,
              'weight': null,
              'grading_allocation_id': 1
            }
          ],
          'is_active': 1,
          'display_the_general_average_grade': 1,
          'grading_management': [
            {
              'id': 6,
              'model_type': 'grade',
              'model_id': 17,
              'name': 'G10',
              'grading_allocation_id': 1,
              'division_id': 2,
              'section_id': 4,
              'grade_id': 17,
              'division_name': 'AMERICAN',
              'section_name': 'High',
              'grade_name': 'G10'
            }
          ]
        },
        {
          'id': 2,
          'school_id': 1,
          'name':' allocation name',
          'grading_type': [],
          'user_id': 9,
          'user': 'SND',
          'Academic_id': null,
          'Academic_name': '2021-2021',
          'start_date': '2021-12-05',
          'end_date': '2021-12-09',
          'apply_credit_hours': 0,
          'subjects': [
            {
              'id': 5,
              'subject_id': 1,
              'add_to_grading_allocation': 1,
              'display_to_report_cared': 1,
              'weight': null,
              'grading_allocation_id': 2
            },
            {
              'id': 6,
              'subject_id': 4,
              'add_to_grading_allocation': 1,
              'display_to_report_cared': 1,
              'weight': null,
              'grading_allocation_id': 2
            },
            {
              'id': 7,
              'subject_id': 5,
              'add_to_grading_allocation': 1,
              'display_to_report_cared': 1,
              'weight': null,
              'grading_allocation_id': 2
            },
            {
              'id': 8,
              'subject_id': 6,
              'add_to_grading_allocation': 1,
              'display_to_report_cared': 1,
              'weight': null,
              'grading_allocation_id': 2
            }
          ],
          'is_active': 0,
          'display_the_general_average_grade': 1,
          'grading_management': [
            {
              'id': 7,
              'model_type': 'grade',
              'model_id': 17,
              'name': 'G10',
              'grading_allocation_id': 2,
              'division_id': 2,
              'section_id': 4,
              'grade_id': 17,
              'division_name': 'AMERICAN',
              'section_name': 'High',
              'grade_name': 'G10'
            }
          ]
        },
        {
          'id': 3,
          'school_id': 1,
          'name':' allocation name',
          'grading_type': [
            {
              'grading_type_id': 14,
              'weight': '40.00',
              'name': 'Final Exam',
              'title': 'final exam title'
            },
            {
              'grading_type_id': 15,
              'weight': '30.00',
              'name': 'Mid Exam',
              'title': 'mid exam title'
            },
            {
              'grading_type_id': 16,
              'weight': '40.00',
              'name': 'Project',
              'title': 'project title'
            }
          ],
          'user_id': 9,
          'user': 'SND',
          'Academic_id': null,
          'Academic_name': '2021-2021',
          'start_date': '2022-01-05',
          'end_date': '2022-01-09',
          'apply_credit_hours': 0,
          'subjects': [
            {
              'id': 9,
              'subject_id': 1,
              'add_to_grading_allocation': 1,
              'display_to_report_cared': 1,
              'weight': null,
              'grading_allocation_id': 3
            },
            {
              'id': 10,
              'subject_id': 4,
              'add_to_grading_allocation': 1,
              'display_to_report_cared': 1,
              'weight': null,
              'grading_allocation_id': 3
            },
            {
              'id': 11,
              'subject_id': 5,
              'add_to_grading_allocation': 1,
              'display_to_report_cared': 1,
              'weight': null,
              'grading_allocation_id': 3
            },
            {
              'id': 12,
              'subject_id': 6,
              'add_to_grading_allocation': 1,
              'display_to_report_cared': 1,
              'weight': null,
              'grading_allocation_id': 3
            }
          ],
          'is_active': 1,
          'display_the_general_average_grade': 1,
          'grading_management': [
            {
              'id': 9,
              'model_type': 'grade',
              'model_id': 17,
              'name': 'G10',
              'grading_allocation_id': 3,
              'division_id': 2,
              'section_id': 4,
              'grade_id': 17,
              'division_name': 'AMERICAN',
              'section_name': 'High',
              'grade_name': 'G10'
            }
          ]
        },
        {
          'id': 4,
          'school_id': 1,
          'name':' allocation name',
          'grading_type': [
            {
              'grading_type_id': 14,
              'weight': '40.00',
              'name': 'Final Exam',
              'title': 'final exam title'
            },
            {
              'grading_type_id': 15,
              'weight': '30.00',
              'name': 'Mid Exam',
              'title': 'mid exam title'
            },
            {
              'grading_type_id': 16,
              'weight': '40.00',
              'name': 'Project',
              'title': 'project title'
            }
          ],
          'user_id': 9,
          'user': 'SND',
          'Academic_id': null,
          'Academic_name': '2021-2021',
          'start_date': '2022-11-05',
          'end_date': '2022-11-09',
          'apply_credit_hours': 0,
          'subjects': [
            {
              'id': 13,
              'subject_id': 1,
              'add_to_grading_allocation': 1,
              'display_to_report_cared': 1,
              'weight': null,
              'grading_allocation_id': 4
            },
            {
              'id': 14,
              'subject_id': 4,
              'add_to_grading_allocation': 1,
              'display_to_report_cared': 1,
              'weight': null,
              'grading_allocation_id': 4
            },
            {
              'id': 15,
              'subject_id': 5,
              'add_to_grading_allocation': 1,
              'display_to_report_cared': 1,
              'weight': null,
              'grading_allocation_id': 4
            },
            {
              'id': 16,
              'subject_id': 6,
              'add_to_grading_allocation': 1,
              'display_to_report_cared': 1,
              'weight': null,
              'grading_allocation_id': 4
            }
          ],
          'is_active': 0,
          'display_the_general_average_grade': 1,
          'grading_management': [
            {
              'id': 11,
              'model_type': 'grade',
              'model_id': 18,
              'name': 'G11',
              'grading_allocation_id': 4,
              'division_id': 2,
              'section_id': 4,
              'grade_id': 18,
              'division_name': 'AMERICAN',
              'section_name': 'High',
              'grade_name': 'G11'
            }
          ]
        },
        {
          'id': 5,
          'school_id': 1,
          'name':' allocation name',
          'grading_type': [
            {
              'grading_type_id': 14,
              'weight': '40.00',
              'name': 'Final Exam',
              'title': 'final exam title'
            },
            {
              'grading_type_id': 15,
              'weight': '30.00',
              'name': 'Mid Exam',
              'title': 'mid exam title'
            },
            {
              'grading_type_id': 16,
              'weight': '40.00',
              'name': 'Project',
              'title': 'project title'
            }
          ],
          'user_id': 9,
          'user': 'SND',
          'Academic_id': null,
          'Academic_name': '2021-2021',
          'start_date': '2022-11-05',
          'end_date': '2022-11-09',
          'apply_credit_hours': 0,
          'subjects': [
            {
              'id': 17,
              'subject_id': 1,
              'add_to_grading_allocation': 1,
              'display_to_report_cared': 1,
              'weight': null,
              'grading_allocation_id': 5
            },
            {
              'id': 18,
              'subject_id': 4,
              'add_to_grading_allocation': 1,
              'display_to_report_cared': 1,
              'weight': null,
              'grading_allocation_id': 5
            },
            {
              'id': 19,
              'subject_id': 5,
              'add_to_grading_allocation': 1,
              'display_to_report_cared': 1,
              'weight': null,
              'grading_allocation_id': 5
            },
            {
              'id': 20,
              'subject_id': 6,
              'add_to_grading_allocation': 1,
              'display_to_report_cared': 1,
              'weight': null,
              'grading_allocation_id': 5
            }
          ],
          'is_active': 1,
          'display_the_general_average_grade': 1,
          'grading_management': [
            {
              'id': 12,
              'model_type': 'grade',
              'model_id': 17,
              'name': 'G10',
              'grading_allocation_id': 5,
              'division_id': 2,
              'section_id': 4,
              'grade_id': 17,
              'division_name': 'AMERICAN',
              'section_name': 'High',
              'grade_name': 'G10'
            }
          ]
        },
        {
          'id': 9,
          'school_id': 1,
          'name':' allocation name',
          'grading_type': [
            {
              'grading_type_id': 14,
              'weight': '40.00',
              'name': 'Final Exam',
              'title': 'final exam title'
            },
            {
              'grading_type_id': 15,
              'weight': '30.00',
              'name': 'Mid Exam',
              'title': 'mid exam title'
            },
            {
              'grading_type_id': 16,
              'weight': '40.00',
              'name': 'Project',
              'title': 'project title'
            }
          ],
          'user_id': 9,
          'user': 'SND',
          'Academic_id': null,
          'Academic_name': '2021-2021',
          'start_date': '2022-11-05',
          'end_date': '2022-11-09',
          'apply_credit_hours': 0,
          'subjects': [
            {
              'id': 25,
              'subject_id': 1,
              'add_to_grading_allocation': 1,
              'display_to_report_cared': 1,
              'weight': null,
              'grading_allocation_id': 9
            },
            {
              'id': 26,
              'subject_id': 4,
              'add_to_grading_allocation': 1,
              'display_to_report_cared': 1,
              'weight': null,
              'grading_allocation_id': 9
            },
            {
              'id': 27,
              'subject_id': 5,
              'add_to_grading_allocation': 1,
              'display_to_report_cared': 1,
              'weight': null,
              'grading_allocation_id': 9
            }
          ],
          'is_active': 1,
          'display_the_general_average_grade': 1,
          'grading_management': [
            {
              'id': 15,
              'model_type': 'grade',
              'model_id': 19,
              'name': 'G12',
              'grading_allocation_id': 9,
              'division_id': 2,
              'section_id': 4,
              'grade_id': 19,
              'division_name': 'AMERICAN',
              'section_name': 'High',
              'grade_name': 'G12'
            }
          ]
        }
      ],
      'total': 6,
      'count': 6,
      'per_page': '10',
      'current_page': 1,
      'last_page': 1
    }
  },
  types :{
    'status': 'success',
    'data': [
      {
        'id': 12,
        'key': 'assignment',
        'name': 'Assignment'
      },
      {
        'id': 13,
        'key': 'task',
        'name': 'Task'
      },
      {
        'id': 14,
        'key': 'final_exam',
        'name': 'Final Exam'
      },
      {
        'id': 15,
        'key': 'mid_exam',
        'name': 'Mid Exam'
      },
      {
        'id': 16,
        'key': 'project',
        'name': 'Project'
      },
      {
        'id': 17,
        'key': 'behavior',
        'name': 'Behavior'
      },
      {
        'id': 18,
        'key': 'classwork',
        'name': 'Classwork'
      },
      {
        'id': 19,
        'key': 'Attendance',
        'name': 'Attendance'
      },
      {
        'id': 20,
        'key': 'research_presentation',
        'name': 'Research & presentation'
      }
    ]
  },
  show:{
    'status': 'success',
    'data': {
      'id': 9,
      'school_id': 1,
      'name': 'grading g 17',

      'user_id': 9,
      'user': 'SND',
      'Academic_id': '60db355a9531a63cff3d2d52',
      'Academic_name': '2021-2021',
      'start_date': '2022-01-14',
      'end_date': '2022-01-25',
      'apply_credit_hours': 0,
      'subjects': [
        {
          'id': 25,
          'subject_id': 1,
          'add_to_grading_allocation': 1,
          'display_to_report_cared': 1,
          'weight': null,
          'grading_allocation_id': 9
        },
        {
          'id': 26,
          'subject_id':6,
          'add_to_grading_allocation': 1,
          'display_to_report_cared': 1,
          'weight': null,
          'grading_allocation_id': 9
        },
        {
          'id': 27,
          'subject_id': 7,
          'add_to_grading_allocation': 1,
          'display_to_report_cared': 1,
          'weight': null,
          'grading_allocation_id': 9
        },
        {
          'id': 28,
          'subject_id':17,
          'add_to_grading_allocation': 1,
          'display_to_report_cared': 1,
          'weight': null,
          'grading_allocation_id': 9
        },
        {
          'id': 29,
          'subject_id':21,
          'add_to_grading_allocation': 1,
          'display_to_report_cared': 0,
          'weight': null,
          'grading_allocation_id': 9
        },
        {
          'id': 30,
          'subject_id':22,
          'add_to_grading_allocation': 0,
          'display_to_report_cared': 1,
          'weight': null,
          'grading_allocation_id': 9
        },
        {
          'id': 31,
          'subject_id':23,
          'add_to_grading_allocation': 0,
          'display_to_report_cared': 1,
          'weight': null,
          'grading_allocation_id': 9
        }
      ],
      'display_the_general_average_grade': 1,
      'grading_management': [
        {
          'id': 14,
          'model_type': 'grade',
          'model_id': 19,
          'name': 'G12',
          'grading_allocation_id': 9,
          'division_id': 1,
          'section_id': 1,
          'grade_id': 1,
          'division_name': 'AMERICAN',
          'section_name': 'High',
          'grade_name': 'G12'
        }
      ],
      'grading_type': [
        {
          'grading_type_id': 14,
          'weight': '40.00',
          'name': 'Final Exam',
          'title': 'final exam title'
        },
        {
          'grading_type_id': 15,
          'weight': '30.00',
          'name': 'Mid Exam',
          'title': 'mid exam title'
        },
        {
          'grading_type_id': 16,
          'weight': '40.00',
          'name': 'Project',
          'title': 'project title'
        }
      ]
    }
  }
}
