<template>
  <vx-card :showLoading="formLoading">
    <div class="lg:w-3/4 w-full mx-auto">
      <!-- name -->
      <div class="mb-5 w-full">
        <label
          >{{ $t('form.name') }}
          <sup class="text-danger">*</sup>
        </label>

        <vs-input
          v-model="allocation.name"
          v-validate="'required'"
          class="w-full"
          name="name" />
        <span class="text-danger text-sm">{{
          errors.first('name') | text_formatter
        }}</span>
      </div>
      <div class="mb-5 w-full">
        <div class="flex">
          <vs-switch v-model="allocation.is_strand" />
          <label class="mx-3">{{ $t('grading.strands_approach') }}</label>
        </div>
        <vs-divider class="my-5 w-full" />
      </div>

      <!-- Choose term  -->
      <template>
        <div class="mb-5 w-full">
          <label
            >{{ $t('form.choose_division') }}
            <sup class="text-danger">*</sup>
          </label>

          <v-select
            label="name"
            :reduce="(d) => d.id"
            v-model="allocation.division_id"
            v-validate="'required'"
            class="w-full"
            name="divisions"
            :options="divisions"
            @input="getSections"
            :disabled="!divisionLoading && !divisions.length"
            :showLoadingIcon="divisionLoading" />
          <span class="text-danger text-sm">{{
            errors.first('divisions') | text_formatter
          }}</span>
        </div>
        <div class="mb-5 w-full">
          <label
            >{{ $t('form.choose_section') }}
            <sup class="text-danger">*</sup>
          </label>

          <v-select
            label="name"
            :reduce="(d) => d.id"
            v-model="allocation.section_id"
            v-validate="'required'"
            class="w-full"
            name="sections"
            :options="filtratedSections"
            @input="getGrades"
            :disabled="!sectionLoading && !filtratedSections.length"
            :showLoadingIcon="sectionLoading" />
          <span class="text-danger text-sm">{{
            errors.first('sections') | text_formatter
          }}</span>
        </div>
        <div class="mb-5 w-full">
          <label
            >{{ $t('form.choose_grade') }}
            <sup class="text-danger">*</sup>
          </label>

          <v-select
            label="name"
            :reduce="(d) => d.id"
            v-model="allocation.grade_id"
            v-validate="'required'"
            class="w-full"
            name="grades"
            :options="grades"
            :disabled="!gradeLoading && !grades.length"
            :showLoadingIcon="gradeLoading" />
          <span class="text-danger text-sm">{{
            errors.first('grades') | text_formatter
          }}</span>
        </div>
        <div class="mb-5 w-full">
          <div class="vx-row">
            <label class="vx-col w-full"
              >{{ $t('grading.select_term_period') }}
              <sup class="text-danger">*</sup></label
            >
            <div class="vx-col md:w-3/4 w-full">
              <date-filter
                :showLabel="false"
                :minDate="academicYearDates.start_date"
                :maxDate="academicYearDates.end_date"
                :dates="selectedPeriod"
                :setDatesFirstTime="editId ? editLoadData : loadDates"
                @refilter="(v) => (selectedPeriod = v)" />
            </div>
            <div class="vx-col md:w-1/4 w-full">
              <vs-button
                @click="refreshSubjects()"
                type="border"
                class="w-full"
                :disabled="!allocation.grade_id || subjectsLoading"
                >{{ $t('form.get_subjects') }}</vs-button
              >
            </div>
          </div>
          <!-- <span
            class="text-danger text-sm block"
            v-if="!selectedPeriod.start_Date || !selectedPeriod.end_Date"
            >{{ $t("grading.the_term_period_is_required") }}</span
          > -->
        </div>
      </template>

      <!-- Subjects -->
      <div
        class="w-full"
        ref="subjectsSection"
        :class="{'have-loader': subjectsLoading}">
        <vs-divider class="my-5" />
        <!-- Loader -->
        <div
          ref="subjectsLoader"
          id="subjectsLoader"
          class="vs-con-loading__container"
          v-if="subjectsLoading" />
        <!-- Info -->
        <div class="mb-10 w-full">
          <h6>
            <strong>{{
              $t('grading.the_subjects_of_the_selected_period') | text_formatter
            }}</strong>
          </h6>
          <ul class="text-sm" v-if="!isMYP">
            <li
              :class="{
                'text-success': creditHoursWithSubjectWeight === true,
                'text-danger': creditHoursWithSubjectWeight === false
              }">
              {{
                $t(
                  'grading.you_must_enter_the_subject_weight_at_least_or_inactivate_credit_hours'
                ) | text_formatter
              }}
            </li>
            <li
              :class="{
                'text-success': subjectWeightsValid === true,
                'text-danger': subjectWeightsValid === false
              }">
              {{
                ($t('grading.the_subject_weight_must_be_one_of') +
                  ' ' +
                  subjectWeights.join(' , '))
                  | text_formatter
              }}
            </li>
          </ul>
        </div>
        <!-- Subjects List  -->
        <template v-if="subjects.length">
          <vs-table
            class="m-auto"
            :data="allocationSubjects"
            :hoverFlat="true"
            id="allocation-subjects-table">
            <!-- Header -->
            <template slot="thead">
              <vs-th class="empty"></vs-th>
              <vs-th v-if="!isMYP && !isStrandsApproach">
                <vs-switch
                  v-model="allocation.apply_credit_hours"
                  name="apply_credit_hours" />
                <span class="mx-2">{{
                  $t('grading.apply_credit_hours')
                }}</span></vs-th
              >
              <vs-th>{{ $t('grading.include_in_allocation') }}</vs-th>
              <vs-th>{{ $t('grading.display_in_Report_card') }}</vs-th>
            </template>
            <!-- Rows -->
            <template v-slot="{data}">
              <vs-tr
                v-for="(tr, indextr) in (data = allocationSubjects)"
                :data="tr"
                :key="indextr">
                <vs-td>{{ tr.name }}</vs-td>
                <vs-td v-if="!isMYP && !isStrandsApproach">
                  <div class="w-1/2 mx-auto">
                    <vs-input
                      v-model="tr.weight"
                      placeholder="00"
                      max="5"
                      min="0.5"
                      type="number"
                      class="no-arrows"
                      v-validate="{
                        max_value: 5,
                        min_value: 0.5,
                        required:
                          Boolean(allocation.apply_credit_hours) &&
                          (Boolean(tr.add_to_grading_allocation) ||
                            Boolean(tr.display_to_report_cared))
                      }"
                      name="credit_hours"
                      :danger="
                        (Boolean(tr.weight) &&
                          !isSubjectWeightValid(tr.weight)) ||
                        (!Boolean(tr.weight) &&
                          Boolean(allocation.apply_credit_hours) &&
                          (Boolean(tr.add_to_grading_allocation) ||
                            Boolean(tr.display_to_report_cared)))
                      "
                      :disabled="!allocation.apply_credit_hours" />
                  </div>
                  <div class="w-full mx-auto text-center">
                    <p
                      class="text-sm text-center text-danger"
                      v-if="
                        Boolean(tr.weight) && !isSubjectWeightValid(tr.weight)
                      ">
                      {{
                        $t('grading.must_be_one_of') +
                        ' ' +
                        subjectWeights.join(' , ')
                      }}
                    </p>
                  </div>
                </vs-td>
                <vs-td>
                  <vs-switch
                    v-model="tr.add_to_grading_allocation"
                    class="mx-auto"
                    @change="
                      (e) => {
                        if (tr.add_to_grading_allocation)
                          tr.display_to_report_cared = true
                      }
                    " />
                </vs-td>
                <vs-td>
                  <vs-switch
                    v-model="tr.display_to_report_cared"
                    class="w-1/2" />
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
          <span class="text-danger text-sm">{{
            errors.first('credit_hours') | text_formatter
          }}</span>
        </template>

        <p v-else-if="!subjectsLoading" class="text-center">
          <span v-if="noSelectedPeriod">
            {{
              $t('grading.select_the_period_and_grade_to_get_the_subjects')
                | text_formatter
            }}</span
          >
          <span v-else>
            {{
              $t('grading.theres_no_subjects_in_this_period') | text_formatter
            }}
          </span>
        </p>
      </div>
      <!-- Types -->
      <template v-if="!isStrandsApproach">
        <div
          class="mb-5 w-full"
          ref="typesSection"
          :class="{'have-loader': typesLoading}">
          <vs-divider class="my-5" />

          <!-- Info -->
          <div class="mb-10 w-full">
            <h6>
              <strong>{{
                $t('grading.assessment_models') | text_formatter
              }}</strong>
            </h6>
            <p
              v-if="!isMYP"
              class="text-sm"
              :class="{
                'text-success': assessmentsWeightSumValid === true,
                'text-danger': assessmentsWeightSumValid === false
              }">
              {{
                $t(
                  'grading.the_allocation_must_have_at_least_one_type_and_the_total_weights_must_be_equal_100'
                ) | text_formatter
              }}
            </p>
          </div>
          <!-- Types list   -->
          <template v-if="allocationTypes.length">
            <div
              class="mb-6 vx-row items-center"
              v-for="(type, i) in allocationTypes"
              :key="type.id">
              <div class="vx-col md:w-1/3 w-full md:mb-0 mb-2">
                <vs-checkbox
                  v-model="type.addToAllocation"
                  :disabled="type.addToAllocation && isIncludeOnlyOneType"
                  >{{ type.name }}
                </vs-checkbox>
              </div>
              <template v-if="type.addToAllocation">
                <div class="vx-col md:w-1/2 w-3/4">
                  <vs-input
                    v-model="type.title"
                    v-validate="'required'"
                    :placeholder="$t('basic.title')"
                    :name="`${type.name}_name`"
                    :danger="errors.has(`${type.name}_name`)" />
                </div>
                <template v-if="!isMYP">
                  <div class="vx-col md:w-1/6 w-1/4">
                    <vs-input
                      :danger="
                        errors.has(`${type.name}_weight`) ||
                        (Boolean(type.weight) &&
                          assessmentsWeightSumValid === false)
                      "
                      v-model="type.weight"
                      :name="`${type.name}_weight`"
                      icon-pack="feather"
                      icon="icon-percent"
                      icon-after
                      vs-icon-after="true"
                      max="100"
                      min="1"
                      type="number"
                      class="no-arrows"
                      v-validate="{
                        required: true,
                        numeric: true,
                        max_value: 100,
                        min_value: 1
                      }" />
                  </div>
                  <div
                    class="vx-col md:w-2/3 w-full"
                    :class="$vs.rtl ? 'mr-auto' : 'ml-auto'">
                    <span
                      class="text-danger text-sm mt-2"
                      v-if="
                        errors.has(`${type.name}_name`) ||
                        errors.has(`${type.name}_weight`)
                      "
                      >{{
                        (!type.title || !type.weight
                          ? `${type.name} ${$t(
                              'grading.title_and_weight_are_required'
                            )} `
                          : errors.first(`${type.name}_name`) ||
                            errors.first(`${type.name}_weight`))
                          | text_formatter
                      }}</span
                    >
                  </div>
                </template>
              </template>
            </div>
          </template>
          <!-- Loader -->
          <div
            ref="typesLoader"
            id="typesLoader"
            class="vs-con-loading__container"
            v-if="typesLoading" />
        </div>
      </template>
      <!-- apply average grade -->
      <div class="mb-5 w-full">
        <vs-divider class="my-5" />
        <vs-checkbox
          v-model="allocation.display_the_general_average_grade"
          :vs-value="true"
          :disabled="isAmerican || isStrandsApproach"
          >{{ $t('grading.apply_and_display_The_general_average_grade') }}
        </vs-checkbox>
      </div>
      <!-- Actions -->
      <div class="w-full mt-10 flex justify-between items-center">
        <vs-button
          class="mb-5"
          type="border"
          color="warning"
          @click="$router.back()"
          >{{ $t('basic.cancel') }}</vs-button
        >

        <vs-button
          @click="submit($event, true)"
          class="mb-5"
          v-if="editId"
          color="gray"
          :disabled="editId && !allocation.name"
          >{{ $t('basic.duplicate') }}
        </vs-button>
        <vs-button
          @click="submit()"
          class="mb-5"
          :disabled="editId && !allocation.name"
          >{{ editId ? $t('basic.update') : $t('basic.submit') }}</vs-button
        >
      </div>
    </div>
  </vx-card>
</template>
<script>
import {commonDataMixin} from '@/mixins.js'
import dateFilter from '@/components/general/DateFilter.vue'
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'
// TODO : Remove it after fix the integration

import staticData from '../static_data.js'
const GradingRepository = RepositoryFactory.get('GradingModule', 'common'),
  AllocationsRepository = RepositoryFactory.get('GradingModule', 'allocations'),
  AcademicYearRepository = RepositoryFactory.get(
    'AcademicModule',
    'academicYears'
  )

export default {
  name: 'allocationForm',
  mixins: [commonDataMixin],
  components: {dateFilter},
  props: {
    data: {type: Object, default: () => {}},
    loading: {type: Boolean, default: false}
  },
  data: () => ({
    //////////////////////////
    //  loading
    //////////////////////////
    divisionLoading: false,
    gradeLoading: false,
    sectionLoading: false,
    formLoading: false,
    typesLoading: true,
    subjectsLoading: false,
    loadDates: false,
    //////////////////////////
    //  Data
    //////////////////////////
    divisions: [],
    sections: [],
    filtratedSections: [],
    grades: [],
    subjects: [],
    gradingTypes: [],
    currentAcademicYear: {},
    academicYearDates: {},
    subjectWeights: [0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5],
    //////////////////////////
    // Allocation
    //////////////////////////
    // INFO: for edit mode, set allocation props manually at handleAllocationShow()
    allocation: {
      name: '',
      division_id: null,
      grade_id: null,
      section_id: null,
      apply_credit_hours: false,
      display_the_general_average_grade: true,
      is_strand: false
    },
    selectedPeriod: {},
    allocationSubjects: [],
    allocationTypes: [],
    //////////////////////////
    //  Edit
    //////////////////////////
    editLoadData: false,
    editAllocationData: {},
    editLoadSubjectsData: false,
    //////////////////////////
    //  validation
    //////////////////////////
    creditHoursWithSubjectWeight: null,
    assessmentsWeightSumValid: null,
    subjectWeightsValid: null,
    //////////////////////////
    //  Static Mode
    //////////////////////////
    staticDataMode: false
  }),
  computed: {
    isIB() {
      return this.allocation.division_id === 1
    },
    isAmerican() {
      return this.allocation.division_id === 2
    },
    isMYP() {
      return this.isIB && this.allocation.section_id === 2
    },
    allocationWeightSum() {
      const types = this.allocationTypes
      return types.length
        ? types
            .filter((e) => e.weight)
            .map((e) => parseFloat(e.weight || 0))
            .reduce((a, b) => a + b)
        : 0
      // return types.length ? types.filter(e => e.weight).map(e => parseFloat(e.weight)).reduce((a, b) => if(a.weight && b.weight) parseFloat(a.weight)+parseFloat(b.weight)) : 0
    },
    noSelectedPeriod() {
      return (
        !this.allocation.grade_id ||
        !this.selectedPeriod.end_date ||
        !this.selectedPeriod.start_date
      )
    },
    isIncludeOnlyOneType() {
      return this.allocationTypes.filter((e) => e.addToAllocation).length === 1
    },
    editId() {
      return this.$route.params.id
    },
    isStrandsApproach() {
      return this.allocation.is_strand
    }
  },
  watch: {
    // selectedPeriod: {
    //   handler (v) {
    //     this.refreshSubjects()
    //   },
    //   deep: true
    // },
    'allocation.grade_id'() {
      this.refreshSubjects()
    },
    loading(v) {
      this.formLoading = v
    },
    allocationTypes(v) {},
    isStrandsApproach(v) {
      this.handleSections()
      this.allocation.display_the_general_average_grade = false
    }
  },
  methods: {
    ////////////////////////
    // Loading
    ////////////////////////
    // Types
    async startTypesLoading() {
      await (this.typesLoading = true)
      this.VSLoading(this.$refs.typesLoader)
    },

    endTypesLoading() {
      this.stopVSLoading(this.$refs.typesLoader)
      this.typesLoading = false
    },

    // subjects
    async startSubjectsLoading() {
      await (this.subjectsLoading = true)
      this.VSLoading(this.$refs.subjectsLoader)
    },
    endSubjectsLoading() {
      this.stopVSLoading(this.$refs.subjectsLoader)
      this.subjectsLoading = false
    },
    ////////////////////////
    // APIs
    ////////////////////////

    async getDivisions() {
      if (!this.editLoadData) this.resetDivisions()
      this.divisionLoading = true

      const payload = {
        schools: [this.authUser.school_id]
      }
      return await this.getFilterData(payload)
        .then((res) => {
          this.divisions = res.divisions
        })
        .finally(() => {
          this.divisionLoading = false
        })
    },
    async getSections() {
      if (!this.editLoadData) this.resetSections()
      this.sectionLoading = true

      const payload = {
        schools: [this.authUser.school_id],
        divisions: [this.allocation.division_id]
      }
      return await this.getFilterData(payload)
        .then((res) => {
          this.handleSections(res.sections)
        })
        .finally(() => {
          this.sectionLoading = false
        })
    },
    async getGrades() {
      this.gradeLoading = true
      if (!this.editLoadData) this.resetGrades()
      const payload = {
        schools: [this.authUser.school_id],
        divisions: [this.allocation.division_id],
        sections: [this.allocation.section_id]
      }
      return await this.getFilterData(payload)
        .then((res) => {
          this.grades = res.grades
        })
        .finally(() => {
          this.gradeLoading = false
        })
    },
    getGradingTypes() {
      this.startTypesLoading()
      GradingRepository.getGradingTypes()
        .then((res) => {
          if (res.data.length > 0) this.handleTypesData(res.data)
        })
        .catch((err) => {
          // this.errMsg(err)
          this.staticDataMode = true
        })
        .finally(() => {
          this.endTypesLoading()
          // TODO : Remove it after fix the integration
          // if (!this.gradingTypes.length && this.staticDataMode) this.handleTypesData(staticData.types.data)
        })
    },
    async getSubjects() {
      if (!this.editLoadData) this.resetSubjects()
      this.startSubjectsLoading()
      if (this.noSelectedPeriod) return
      const payload = {
        grade_id: this.allocation.grade_id,
        start_date: this.selectedPeriod.start_date,
        end_date: this.selectedPeriod.end_date,
        is_strand: this.isStrandsApproach
      }
      return await AllocationsRepository.getSubjectsOfGradeWithinPeriod(payload)
        .then((res) => {
          this.handleSubjectsData(res)
        })
        .finally(this.endSubjectsLoading)
    },
    getAcademicYearInfo(academicYearId) {
      AcademicYearRepository.AcadmicYearInfo(academicYearId)
        .then((res) => {
          this.currentAcademicYear = res.data
          this.setAcademicYearData()
        })
        .catch(this.errMsg)
    },
    getAllocation() {
      if (!this.editId) return
      this.formLoading = true
      this.editLoadData = true
      AllocationsRepository.getAllocation(this.editId)
        .then((res) => {
          this.handleAllocationShow(res.data).then(() => {
            this.editLoadData = false
          })
        })
        .catch((err) => {
          if (!this.staticDataMode) this.errMsg(err)
          else if (!this.editAllocationData.id) {
            this.handleAllocationShow(staticData.show.data).then(() => {
              this.editLoadData = false
            })
          }
        })
        .finally(() => {
          this.formLoading = false
          // TODO : Remove it after fix the integration
        })
    },
    createAllocation() {
      this.formLoading = true
      const payload = this.handleAllocationPayload()
      AllocationsRepository.createAllocation(payload)
        .then((res) => {
          this.successMsg(this.$t('form.saved_successfully'))
          this.$router.push({name: 'allocations'})
        })
        .catch(this.errMsg)
        .finally(() => {
          this.formLoading = false
        })
    },
    editAllocation() {
      this.formLoading = true
      const payload = this.handleAllocationPayload()
      AllocationsRepository.editAllocation(this.editId, payload)
        .then((res) => {
          this.successMsg(this.$t('form.saved_successfully'))
          this.$router.push({name: 'allocations'})
        })
        .catch(this.errMsg)
        .finally(() => {
          this.formLoading = false
        })
    },
    ////////////////////////
    // Reset && Recall && set
    ////////////////////////
    resetDivisions() {
      this.divisions = this.filtratedSections = this.grades = []
      this.allocation.division_id = null
      this.allocation.section_id = null
      this.allocation.grade_id = null
    },
    resetSections() {
      this.filtratedSections = this.grades = []
      this.allocation.section_id = null
      this.allocation.grade_id = null
    },
    resetGrades() {
      this.grades = []
      this.allocation.grade_id = null
    },
    refreshSubjects() {
      if (
        this.allocation.grade_id &&
        this.selectedPeriod.end_date &&
        this.selectedPeriod.start_date
      )
        this.getSubjects()
      else this.resetSubjects()
    },
    resetSubjects() {
      this.subjects = []
      this.allocationSubjects = []
    },
    ////////////////////////
    // on Change
    ////////////////////////
    checkGradeType(event, typeId) {
      if (event.target.value === 'true') this.addGradeType(typeId)
      else this.removeGradeType(typeId)
    },
    addGradeType(typeId, weight = null, title = null) {
      this.allocation.grading_type.push({
        grading_type_id: typeId,
        weight: this.isMYP ? null : weight || 100 - this.allocationWeightSum,
        title: title || null
      })
    },
    removeGradeType(typeId) {
      const elIndex = this.allocation.grading_type.findIndex(
        (e) => e.grading_type_id === typeId
      )
      this.allocation.grading_type.splice(
        this.allocation.grading_type[elIndex],
        1
      )
    },
    setAcademicYearData() {
      return new Promise((resolve, reject) => {
        this.loadDates = true
        const terms = this.currentAcademicYear.terms,
          termsCount = terms?.length || 0,
          firstTermStartDate = termsCount ? terms[0]?.start_date : null,
          lastTermEndDate = termsCount ? terms[termsCount - 1]?.end_date : null

        this.academicYearDates.start_date =
          this.currentAcademicYear.start_date || firstTermStartDate
        this.academicYearDates.end_date =
          this.currentAcademicYear.end_date || lastTermEndDate
        this.selectedPeriod = this.academicYearDates
        resolve(true)
      })
    },
    async startupAPIs() {
      return await (this.getCurrentAcademicYear().then(() => {
        this.setAcademicYearData().then(() => {
          this.loadDates = false
        })
      }),
      this.getDivisions(),
      this.getGradingTypes())
    },

    ////////////////////////
    // Handle Data
    ////////////////////////
    handleAllocationPayload() {
      const payload = window._.cloneDeep(this.allocation)
      payload.subjects = window._.cloneDeep(this.allocationSubjects)
      payload.subjects.forEach((e) => delete e.name)
      if (!this.allocation.apply_credit_hours)
        payload.subjects.forEach((e) => {
          e.weight = null
        })
      payload.school_id = this.authUser.school_id
      payload.grading_type = this.allocationTypes
        .filter((e) => e.addToAllocation)
        .map((e) => ({
          grading_type_id: e.grading_type_id,
          title: e.title,
          weight: this.isMYP ? null : e.weight
        }))
      payload.grading_management = Array.isArray(payload.grade_id)
        ? payload.grade_id.map((e) => ({
            model_type: 'grade',
            model_id: e.id || e
          }))
        : [{model_type: 'grade', model_id: payload.grade_id}]
      payload.start_date = this.selectedPeriod.start_date
      payload.end_date = this.selectedPeriod.end_date
      return payload
    },
    handleAllocationShow(data = this.data) {
      return new Promise((resolve, reject) => {
        this.editAllocationData = data

        // Set the main Data
        ///////////////////////
        if (
          data.Academic_id &&
          data.Academic_id !== this.currentAcademicYear._id
        )
          this.getAcademicYearInfo(data.Academic_id)
        this.selectedPeriod = {
          start_date: data.start_date,
          end_date: data.end_date
        }
        // for (const key in data) {
        //   if (this.allocation[key]) this.allocation[key] = data[key]
        // }

        this.allocation.name = data.name
        this.allocation.display_the_general_average_grade = Boolean(
          data.display_the_general_average_grade
        )
        this.allocation.apply_credit_hours = Boolean(data.apply_credit_hours)
        this.allocation.is_strand = Boolean(data.is_strand)

        // Set term data
        ///////////////////////

        const grades = data.grading_management,
          getDataFromGrades = (key) => {
            return grades.length > 1
              ? grades.map((e) => e[key])
              : grades[0][key]
          }

        this.allocation.division_id = getDataFromGrades('division_id')

        this.getSections().then(() => {
          this.allocation.section_id = getDataFromGrades('section_id')
          this.getGrades().then(() => {
            this.allocation.grade_id = getDataFromGrades('grade_id')
            this.editLoadSubjectsData = true
            resolve(true)
          })
        })
        // Set types
        ////////////////////////
        const isTypesUpdated = this.allocationTypes.some((e) => e.weight)
        if (!isTypesUpdated) {
          const types = this.editAllocationData.grading_type,
            matchType = (id) => types.find((e) => e.grading_type_id === id)
          this.allocationTypes.forEach((e) => {
            e.grading_type_id = e.id
            if (matchType(e.id)) {
              e.weight = this.isMYP
                ? null
                : parseFloat(matchType(e.id).weight || 0)
              e.title = matchType(e.id).title
              e.addToAllocation = Boolean(matchType(e.id))
            }
          })
        }
      })
    },
    handleTypesData(data) {
      data.unshift(
        data.splice(
          data.findIndex((e) => e.key === 'final_exam'),
          1
        )[0]
      )
      this.gradingTypes = data
      this.allocationTypes = window._.cloneDeep(this.gradingTypes)

      if (this.editId) {
        if (this.editAllocationData?.grading_type.length) {
          const types = this.editAllocationData.grading_type,
            matchType = (id) => types.find((e) => e.grading_type_id === id)
          this.allocationTypes.forEach((e) => {
            e.grading_type_id = e.id
            if (matchType(e.id)) {
              e.weight = parseFloat(matchType(e.id).weight || 0)
              e.title = matchType(e.id).title
              e.addToAllocation = matchType(e.id)
            }
          })
        }
      } else {
        this.allocationTypes.forEach((e) => {
          e.grading_type_id = e.id
          e.title = e.name
          if (e.key === 'final_exam') {
            e.weight = 100
            e.title = 'الامتحان النهائى'
            e.addToAllocation = true
          }
        })
      }
      // console.log(this.allocationTypes?.map(e => e.addToAllocation))
    },
    handleSubjectsData(data) {
      this.subjects = data
      if (this.editId && this.editLoadSubjectsData) {
        const getSubject = (id) => this.subjects.find((e) => e.id === id),
          existMatchedSubject = this.editAllocationData.subjects.filter((e) =>
            getSubject(e.subject_id)
          )

        this.allocationSubjects = window._.cloneDeep(existMatchedSubject)
        this.allocationSubjects.forEach((e) => {
          e.name = getSubject(e.subject_id)?.name
        })
        this.editLoadSubjectsData = false
      } else {
        this.allocationSubjects = window._.cloneDeep(this.subjects).map(
          (e) => ({
            subject_id: e.id,
            name: e.name,
            weight: null,
            add_to_grading_allocation: false,
            display_to_report_cared: false
          })
        )
        if (this.allocationSubjects[0]) {
          this.allocationSubjects[0].add_to_grading_allocation = true
          this.allocationSubjects[0].display_to_report_cared = true
        }
      }
    },
    handleStaticMode() {
      this.$route.params.staticData = this.handleAllocationPayload()
      this.successMsg(this.$t('form.saved_successfully'))
      this.$router.push({name: 'allocations'})
    },
    handleSections(data = this.sections) {
      this.sections = data
      if (this.isStrandsApproach && this.isIB)
        this.filtratedSections = [data.find((s) => s.id === 1)]
      else this.filtratedSections = data
    },
    ////////////////////////
    // Check Validation
    ////////////////////////
    isSubjectWeightValid(weight) {
      return this.subjectWeights.some((e) => e === parseFloat(weight || 0))
    },
    isAllocationSubjectsWeightValid() {
      if (this.isStrandsApproach) return true
      const weights = this.allocationSubjects.map((e) =>
        parseFloat(e.weight || 0)
      )
      // if(weights)
    },
    isCreditHoursValid() {
      if (this.isStrandsApproach) return true
      const isApplied = this.allocation.apply_credit_hours,
        haveWeight = this.allocationSubjects.some((e) => e.weight),
        weights = this.allocationSubjects.map((e) => parseFloat(e.weight || 0)),
        validWeights = weights.some((e) =>
          this.subjectWeights.some((s) => s === parseFloat(e))
        )

      if (isApplied) {
        if (!haveWeight || !validWeights) {
          this.scrollToTop('subjectsSection')
          if (!haveWeight) this.creditHoursWithSubjectWeight = false
          if (!validWeights) this.subjectWeightsValid = false
          this.errMsg(
            this.$t(
              'grading.you_must_enter_the_subject_weight_at_least_or_inactivate_credit_hours'
            )
          )
          return false
        } else {
          if (haveWeight) this.creditHoursWithSubjectWeight = true
          if (validWeights) this.subjectWeightsValid = true
          return true
        }
      } else {
        return true
      }
    },
    isAllocationAssessmentsWeightSumValid() {
      if (this.isStrandsApproach) return true

      const weights = this.allocationTypes.filter((e) => e.weight),
        weightSum = weights.length
          ? weights
              .map((e) => parseFloat(e.weight || 0))
              .reduce((a, b) => a + b)
          : 0

      if (!this.isMYP && weightSum !== 100) {
        // alert(weightSum)
        this.assessmentsWeightSumValid = false
        this.scrollToTop('typesSection')

        this.errMsg(
          this.$t(
            'grading.the_allocation_must_have_at_least_one_type_and_the_total_weights_must_be_equal_100'
          )
        )
        return false
      } else {
        this.assessmentsWeightSumValid = true
        return true
      }
    },
    ////////////////////////
    // Submit
    ////////////////////////
    submit($event, duplicate = false) {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          // console.log(this.isCreditHoursValid())
          // console.log(this.isAllocationAssessmentsWeightSumValid())
          if (
            this.isCreditHoursValid() &&
            this.isAllocationAssessmentsWeightSumValid() &&
            this.allocationSubjects.length
          ) {
            // if (!this.staticDataMode) {
            if (duplicate || !this.editId) this.createAllocation()
            else this.editAllocation()

            // } else {
            //   this.handleStaticMode()
            // }
          } else this.errMsg()
        } else this.errMsg()
      })
    }
  },
  created() {
    this.startupAPIs().then(() => {
      if (this.editId) this.getAllocation()
    })
  }
}
</script>
<style lang="scss">
#allocation-subjects-table {
  td .vs-switch {
    margin-right: auto;
    margin-left: auto;
  }
  th {
    text-align: center;
    &:not(.empty) {
      background-color: #ebebeb;
    }
    .vs-table-text {
      justify-content: center;
    }
  }
}
</style>
<style scoped>
.vs-divider {
  margin: 30px 0;
}
</style>
